import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/precisionverify/precision.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutAdmin } from "data/generalService";
import { toast } from "react-toastify";
import { adminLogout, logout, reset } from "features/auth/authSlice";

const Sidebar = () => {
  const { user, admin } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const onLogout = () => {
  //   dispatch(reset());
  //   dispatch(logout());
  //   navigate("/auth");
  // };

  // const onAdminLogout = () => {
  //   dispatch(adminLogout());
  //   dispatch(reset());
  //   navigate("/admin/login");
  // };

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-lg-block d-none"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to={`/`}
        >
          <img
            className="logo sidebar-brand-text mx-3"
            src={logo}
            alt="giving.ng"
          />
        </Link>
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />
        {/* <!-- Nav Item - Dashboard --> */}
        {user && (
          <li className="nav-item  mt-4">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner" ? "present " : ""
              }`}
              to={`/inner`}
              // style={{ color: "red" }}
            >
              <i className="mdi mdi-speedometer mr-2"></i>{" "}
              <span>Dashboard</span>
            </Link>
          </li>
        )}
        {admin && (
          <li className="nav-item  mt-4">
            <Link
              className={`nav-link ${
                window.location.pathname === "/admin" ? "present " : ""
              }`}
              to={`/admin`}
            >
              <i className="mdi mdi-speedometer mr-2"></i>{" "}
              <span>Dashboard</span>
            </Link>
          </li>
        )}
        {user && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/profile" ? "present " : ""
              }`}
              to={`/inner/profile`}
            >
              <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
              <span>Profile</span>
            </Link>
          </li>
        )}
        {admin && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/admin/profile" ? "present " : ""
              }`}
              to={`/admin/profile`}
            >
              <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
              <span>Profile</span>
            </Link>
          </li>
        )}
        {user && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/verification"
                  ? "present "
                  : ""
              }`}
              to={`/inner/verification`}
            >
              <i className="mdi mdi-shield-check-outline mr-2 text-gray-400"></i>{" "}
              <span>Verification</span>
            </Link>
          </li>
        )}
        {user && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/wallet" ? "present " : ""
              }`}
              to={`/inner/wallet`}
            >
              <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
              <span>Wallet</span>
            </Link>
          </li>
        )}
        {admin && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/admin/wallet"
                  ? "present "
                  : ""
              }`}
              to={`/inner/admin/wallet`}
            >
              <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
              <span>Wallet</span>
            </Link>
          </li>
        )}{" "}
        {/*  Remove */}
        {user && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/report" ? "present " : ""
              }`}
              to={`/inner/report`}
            >
              <i className="mdi mdi-chart-box-outline mr-2 text-gray-400"></i>{" "}
              <span>Report</span>
            </Link>
          </li>
        )}
        {user && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/commission"
                  ? "present "
                  : ""
              }`}
              to={`/inner/commission`}
            >
              <i className="mdi mdi-currency-ngn mr-2 text-gray-400"></i>{" "}
              <span>Commission</span>
            </Link>
          </li>
        )}
        {user && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/config-setting"
                  ? "present "
                  : ""
              }`}
              to={`/inner/config-setting`}
            >
              <i className="mdi mdi-chart-box-outline mr-2 text-gray-400"></i>{" "}
              <span>Settings</span>
            </Link>
          </li>
        )}
        {admin && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/inner/users" ? "present " : ""
              }`}
              to={`/inner/users`}
            >
              <i className="mdi mdi-account-group-outline mr-2 text-gray-400"></i>{" "}
              <span>Users</span>
            </Link>
          </li>
        )}
        {admin && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/admin/split-commission"
                  ? "present "
                  : ""
              }`}
              to={`/admin/split-commission`}
            >
              <i className="mdi mdi-currency-ngn mr-2 text-gray-400"></i>{" "}
              <span>Split Commission</span>
            </Link>
          </li>
        )}
        {admin && (
          <li className="nav-item">
            <Link
              className={`nav-link ${
                window.location.pathname === "/admin/settings" ? "present " : ""
              }`}
              to={`/admin/settings`}
            >
              <i className="mdi mdi-cog-transfer-outline mr-2 text-gray-400"></i>{" "}
              <span>Settings</span>
            </Link>
          </li>
        )}
        {/* ---------------------------  Logout --------------------------- */}
        {/* <div className="mt-4">
          {user && (
            <li className="nav-item">
              <p
                onClick={onLogout}
                style={{
                  marginLeft: "26px",
                  padding: "5px",
                  borderRadius: "5px",
                  marginRight: "26px",
                  cursor: "pointer",
                  color: "#f8f8f8",
                  background: "#ec3237",
                }}
              >
                <i className="bi bi-box-arrow-right fa-sm fa-fw mr-2 text-gray-400"></i>
                <span> Logout</span>
              </p>
            </li>
          )}

          {admin && (
            <p
              onClick={onAdminLogout}
              style={{
                marginLeft: "26px",
                padding: "5px",
                borderRadius: "5px",
                marginRight: "26px",
                cursor: "pointer",
                color: "#f8f8f8",

                background: "#ec3237",
              }}
            >
              <i className="bi bi-box-arrow-right fa-sm fa-fw mr-2 text-gray-400"></i>
              <span> Logout</span>
            </p>
          )}
        </div> */}
        {/* <div className="sidebar-heading txt-upper txt-left txt-grey">
          Platform
        </div> */}
      </ul>

      {/* Mobile sidebar (using bootstrap 5 off canvas)*/}
      <div
        className="offcanvas d-block d-lg-none offcanvas-start bg-gradient-primary sidebar-mobile sidebar-dark accordion"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        {/* <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div> */}
        <div className="">
          <ul className="navbar-nav">
            {/* <!-- Sidebar - Brand --> */}
            <Link
              className="sidebar-brand d-flex align-items-center justify-content-center"
              to={`/`}
            >
              <img
                className="logo sidebar-brand-text img-fluid"
                src={logo}
                alt="precisionverify"
              />
            </Link>
            {/* <!-- Divider --> */}
            <hr className="sidebar-divider my-0" />
            {/* <!-- Nav Item - Dashboard --> */}
            {user && (
              <li className="nav-item  mt-4">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner" ? "present " : ""
                  }`}
                  to={`/inner`}
                >
                  <i className="mdi mdi-speedometer mr-2"></i>{" "}
                  <span>Dashboard</span>
                </Link>
              </li>
            )}
            {admin && (
              <li className="nav-item  mt-4">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/admin" ? "present " : ""
                  }`}
                  to={`/admin`}
                >
                  <i className="mdi mdi-speedometer mr-2"></i>{" "}
                  <span>Dashboard</span>
                </Link>
              </li>
            )}
            {user && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/profile"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/profile`}
                >
                  <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
                  <span>Profile</span>
                </Link>
              </li>
            )}
            {admin && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/admin/profile"
                      ? "present "
                      : ""
                  }`}
                  to={`/admin/profile`}
                >
                  <i className="mdi mdi-account-circle-outline mr-2 text-gray-400"></i>{" "}
                  <span>Profile</span>
                </Link>
              </li>
            )}
            {user && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/verification"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/verification`}
                >
                  <i className="mdi mdi-shield-check-outline mr-2 text-gray-400"></i>{" "}
                  <span>Verification</span>
                </Link>
              </li>
            )}
            {user && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/wallet"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/wallet`}
                >
                  <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
                  <span>Wallet</span>
                </Link>
              </li>
            )}
            {admin && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/admin/wallet"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/admin/wallet`}
                >
                  <i className="mdi mdi-wallet-bifold-outline mr-2 text-gray-400"></i>{" "}
                  <span>Wallet</span>
                </Link>
              </li>
            )}{" "}
            {/*  Remove */}
            {user && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/report"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/report`}
                >
                  <i className="mdi mdi-chart-box-outline mr-2 text-gray-400"></i>{" "}
                  <span>Report</span>
                </Link>
              </li>
            )}
            {admin && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/users"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/users`}
                >
                  <i className="mdi mdi-account-group-outline mr-2 text-gray-400"></i>{" "}
                  <span>Users</span>
                </Link>
              </li>
            )}
            {user && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/commission"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/commission`}
                >
                  <i className="mdi mdi-currency-ngn mr-2 text-gray-400"></i>{" "}
                  <span>Commission</span>
                </Link>
              </li>
            )}
            {admin && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/admin/split-commission"
                      ? "present "
                      : ""
                  }`}
                  to={`/admin/split-commission`}
                >
                  <i className="mdi mdi-currency-ngn mr-2 text-gray-400"></i>{" "}
                  <span>Split Commission</span>
                </Link>
              </li>
            )}
            {user && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/inner/config-setting"
                      ? "present "
                      : ""
                  }`}
                  to={`/inner/config-setting`}
                >
                  <i className="mdi mdi-chart-box-outline mr-2 text-gray-400"></i>{" "}
                  <span>Settings</span>
                </Link>
              </li>
            )}
            {admin && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    window.location.pathname === "/admin/settings"
                      ? "present "
                      : ""
                  }`}
                  to={`/admin/settings`}
                >
                  <i className="mdi mdi-cog-transfer-outline mr-2 text-gray-400"></i>{" "}
                  <span>Settings</span>
                </Link>
              </li>
            )}
            {/* <div className="mt-4">
              {user && (
                <li className="nav-item">
                  <p
                    onClick={onLogout}
                    style={{
                      marginLeft: "26px",
                      padding: "5px",
                      borderRadius: "5px",
                      marginRight: "26px",
                      cursor: "pointer",
                      color: "#f8f8f8",
                      background: "#ec3237",
                    }}
                  >
                    <i className="bi bi-box-arrow-right fa-sm fa-fw mr-2 text-gray-400"></i>
                    <span> Logout</span>
                  </p>
                </li>
              )}

              {admin && (
                <p
                  onClick={onAdminLogout}
                  style={{
                    marginLeft: "26px",
                    padding: "5px",
                    borderRadius: "5px",
                    marginRight: "26px",
                    cursor: "pointer",
                    color: "#f8f8f8",

                    background: "#ec3237",
                  }}
                >
                  <i className="bi bi-box-arrow-right fa-sm fa-fw mr-2 text-gray-400"></i>
                  <span> Logout</span>
                </p>
              )}
            </div> */}
            {/* <div className="sidebar-heading txt-upper txt-left txt-grey">
          Platform
        </div> */}
          </ul>
        </div>
      </div>
      {/* Ends here */}
    </>
  );
};

export default Sidebar;
