const FormSelectComponent = ({
  selectName,
  value,
  onChange,
  itemList,
  defValue,
  selectOption,
  size,
}) => {
  const renderList = () => {
    if (itemList && itemList.length !== 0) {
      return itemList.map((item) => {
        return (
          <option key={item.id} value={item.value}>
            {item.caption}
          </option>
        );
      });
    } else {
      return <option>No Item</option>;
    }
  };

  return (
    <div className="col-md-12 form-group input-wrapper">
      <select
        name={selectName}
        value={value}
        onChange={onChange}
        className={`form-control form-select ${size ? size : ""}`}
      >
        <option>{selectOption}</option>
        {renderList()}
      </select>
    </div>
  );
};

export default FormSelectComponent;
