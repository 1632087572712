import {
  apiUrl,
  axiosInstance,
  axiosInstanceAdmin,
  axiosInstanceEmailVerification,
} from "hooks/axiosinstance";

export const getAdmins = async () => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/admins`);
};

// VOLUNTEER REQUEST
export const createAdmin = async (inputData) => {
  return axiosInstanceAdmin.post(`${apiUrl}/admin/admins`, inputData);
};

export const getAdmin = async (id) => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/admins/${id}`);
};
export const updateAdmin = async (inputData, id) => {
  return axiosInstanceAdmin.put(`${apiUrl}/admin/admins/${id}`, inputData);
};
export const deleteAdmin = async (id) => {
  return axiosInstanceAdmin.delete(`${apiUrl}/admin/admins/${id}`);
};

export const restorAdmin = async (id, data) => {
  return axiosInstanceAdmin.patch(`${apiUrl}/admin/admins/${id}/restore`, data);
};

export const assignRole = async (id) => {
  return axiosInstanceAdmin.post(`${apiUrl}/admin/admins/${id}/assign-role`);
};

// ***** Permissions

export const getPermissions = async () => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/permissions`);
};

//   *******Roles
export const getRoles = async () => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/roles`);
};

export const getRole = async (id) => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/roles/${id}`);
};

export const createRole = async (inputData) => {
  return axiosInstanceAdmin.post(`${apiUrl}/admin/roles`, inputData);
};

export const updateRole = async (inputData, id) => {
  return axiosInstanceAdmin.put(`${apiUrl}/admin/roles/${id}`, inputData);
};
export const deleteRole = async (id) => {
  return axiosInstanceAdmin.delete(`${apiUrl}/admin/roles/${id}`);
};

//  set or reset password

export const resetPassword = async (inputData) => {
  return axiosInstanceAdmin.post(
    `${apiUrl}/admin/auth/password/reset`,
    inputData
  );
};

//   Admin forgot password
export const forgetPassword = async (inputData) => {
  return axiosInstanceAdmin.post(
    `${apiUrl}/admin/auth/password/forgot`,
    inputData
  );
};

//   User forgot password
export const forgetUserPassword = async (inputData) => {
  return axiosInstance.post(`${apiUrl}/user/auth/password/forgot`, inputData);
};

//   Reset password for users
export const resetUserPassword = async (inputData) => {
  return axiosInstance.post(`${apiUrl}/user/auth/password/reset`, inputData);
};

//  email verification link resend
export const resendUserEmailLink = async (userCallbackUrl) => {
  return axiosInstanceEmailVerification.get(
    `${apiUrl}/user/auth/email/resend-verification?callbackUrl=${userCallbackUrl}`
  );
};

//  verify email request
export const verifyEmailLink = async (expires, hash, id, signature) => {
  return axiosInstanceEmailVerification.get(
    `${apiUrl}/user/auth/email/verify?expires=${expires}&hash=${hash}&id=${id}&signature=${signature}`
  );
};

// Categories

export const getCategories = async () => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/campaign-categories`);
};

// VOLUNTEER REQUEST
export const createCategory = async (inputData) => {
  return axiosInstanceAdmin.post(
    `${apiUrl}/admin/campaign-categories`,
    inputData
  );
};

export const getCategory = async (id) => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/campaign-categories/${id}`);
};
export const updateCategory = async (inputData, id) => {
  return axiosInstanceAdmin.put(
    `${apiUrl}/admin/campaign-categories/${id}`,
    inputData
  );
};
export const deleteCategory = async (id) => {
  return axiosInstanceAdmin.delete(`${apiUrl}/admin/campaign-categories/${id}`);
};

// get Admin Commission Account Details
export const getAdminCommissionTransactionsHistory = async () => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/getWithdrawalRequest`);
};

// Get Admin Split Commision
export const getAdminSpiltCommission = async () => {
  return axiosInstanceAdmin.get(`${apiUrl}/admin/setting/getCommission`);
};

// Set A commission Percentage
export const setAdminSplitCommission = async (payload) => {
  return axiosInstanceAdmin.post(
    `${apiUrl}/admin/setting/setCommission`,
    payload
  );
};

// Accept Withdrawal
export const acceptWithdrawalRequest = async (id, status) => {
  return axiosInstanceAdmin.post(
    `${apiUrl}/admin/updateWithdrawalStatus/${id}`,
    status
  );
};
// reject Withdrawal
export const rejectWithdrawalRequest = async (id, status) => {
  return axiosInstanceAdmin.post(
    `${apiUrl}/admin/updateWithdrawalStatus/${id}`,
    status
  );
};
