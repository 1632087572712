// App Nav images
import donationSVG from "../../assets/svg/menu_quick_donate.svg";
import donationSVG2 from "../../assets/svg/menu_quick_donate_h.svg";

import crowdfundingSVG from "../../assets/svg/menu_crowdfunding.svg";
import crowdfundingSVG2 from "../../assets/svg/menu_crowdfunding_h.svg";

import ekoshaSVG from "../../assets/svg/menu_ekosha.svg";
import ekoshaSVG2 from "../../assets/svg/menu_ekosha_h.svg";

import vnetworkSVG from "../../assets/svg/menu_volunteer_network.svg";
import vnetworkSVG2 from "../../assets/svg/menu_volunteer_network_h.svg";

import ggSVG from "../../assets/svg/menu_grant.svg";
import ggSVG2 from "../../assets/svg/menu_grant_h.svg";

import givingSVG from "../../assets/svg/menu_giving_network.svg";
import givingSVG2 from "../../assets/svg/menu_giving_network_h.svg";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLogout, logout, reset } from "../../features/auth/authSlice";

const Topbar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const admin = JSON.parse(localStorage.getItem("admin"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstName =
    admin !== null && admin.data.admin
      ? admin.data.admin.first_name
      : user.data.user.first_name;
  const lastName =
    admin !== null && admin.data.admin
      ? admin.data.admin.last_name
      : user.data.user.last_name;

  // const profileImage = data !== null && data.profile_picture ? data.profile_picture : user.data.user.profile_picture;
  const profileImage =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ38QeTbE2jORW_uvGx0TBCXA2g6mnDF6tb_eygeREXSgvdSVXiLZG5hx-D23DuT9IlPNw&usqp=CAU";

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/auth");
  };

  const onAdminLogout = () => {
    dispatch(adminLogout());
    dispatch(reset());
    navigate("/admin/login");
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow inner-top">
        {/* <!-- Sidebar Toggle (Topbar) --> */}
        <span
          id="sidebarToggleTop"
          style={{ fontSize: "30px" }}
          className="d-md-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <i className="mdi mdi-menu"></i>
        </span>

        {/* <!-- Topbar Search --> */}
        <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-light-2 border-0 small"
              placeholder="Search for..."
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button className="btn btn-primary btn-search" type="button">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
        </form>

        {/* <!-- Topbar Navbar --> */}
        <ul className="navbar-nav ml-auto">
          {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="bi bi-search"></i>
            </a>
            {/* <!-- Dropdown - Messages --> */}
            <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="bi bi-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <Link
              className="nav-link dropdown-toggle"
              to={`#`}
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">{`${
                firstName !== undefined ? firstName : ""
              } ${lastName !== undefined ? lastName : ""}`}</span>
              <img
                className="img-profile rounded-circle"
                src={profileImage !== undefined ? profileImage : ""}
                alt="profile"
              />
            </Link>
            {/* <!-- Dropdown - User Information --> */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              {user && (
                <Link className="dropdown-item" to={`/inner/profile`}>
                  <i className="bi bi-person-fill fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </Link>
              )}
              {admin && (
                <Link className="dropdown-item" to={`/admin/profile`}>
                  <i className="bi bi-person-fill fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </Link>
              )}
              {/* <Link className="dropdown-item" to={`#`}>
                        <i className="bi bi-sliders2 fa-sm fa-fw mr-2 text-gray-400"></i>
                        Settings
                    </Link> */}
              {/* <Link className="dropdown-item" to={`#`}>
                        <i className="bi bi-list-ul fa-sm fa-fw mr-2 text-gray-400"></i>
                        Activity Log
                    </Link> */}
              <div className="dropdown-divider"></div>
              {user && (
                <Link onClick={onLogout} className="dropdown-item">
                  <i className="bi bi-box-arrow-right fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </Link>
              )}

              {admin && (
                <Link onClick={onAdminLogout} className="dropdown-item">
                  <i className="bi bi-box-arrow-right fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </Link>
              )}
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>
          {/* App Nav */}
          {/* <li className="nav-item dropdown apps-dropdown no-arrow ">
                        <a className="nav-link dropdown-toggle" href="#" id="appDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="bi bi-app-indicator"></i>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right app-dropdown shadow animated--grow-in" aria-labelledby="appDropdown">
                            <div className="app-dropdown-title">
                                Giving Apps
                            </div>
                            <div className="app-dropdown-items">

                                <div className="app-dropdown-item" data-bs-toggle="collapse" data-bs-target="#collapseDonations" aria-expanded="false" aria-controls="collapseDonations">

                                    <Link to={'/inner/quick-donation'} title={`Quick Donations`}>
                                        <img className="svg-icon" src={donationSVG} alt='icon' />
                                        <img className="svg-icon hover" src={donationSVG2} alt='icon' />
                                    </Link>
                                    <p>Quick Donation</p>
                                </div>

                                <div className="app-dropdown-item" data-bs-toggle="collapse" data-bs-target="#collapseComponents" aria-expanded="false" aria-controls="collapseComponents">

                                    <Link to={`/inner/crowdfunding`} title={`CrowdFunding`}>
                                        <img className="svg-icon" src={crowdfundingSVG} alt='icon' />
                                        <img className="svg-icon hover" src={crowdfundingSVG2} alt='icon' />
                                    </Link>
                                    <p>Crowdfunding</p>
                                </div>

                                <div className="app-dropdown-item" data-bs-toggle="collapse" data-bs-target="#collapseGrant" aria-expanded="false" aria-controls="collapseGrant">
                                    <Link to={"/inner/grant"} title={"Finishing Grant"}>
                                        <img className="svg-icon" src={ggSVG} alt='icon' />
                                        <img className="svg-icon hover" src={ggSVG2} alt='icon' />
                                    </Link>

                                    <p>Finishing Grant</p>
                                </div>



                                <div className="app-dropdown-item" data-bs-toggle="collapse" data-bs-target="#collapseImpact" aria-expanded="false" aria-controls="collapseImpact">
                                    <Link to={"/inner/investment"} title={"Impact Investment"}>
                                        <img className="svg-icon" src={givingSVG} alt='icon' />
                                        <img className="svg-icon hover" src={givingSVG2} alt='icon' />
                                    </Link>
                                    <p>Impact Inestment</p>
                                </div>

                                <div className="app-dropdown-item" data-bs-toggle="collapse" data-bs-target="#collapseGroup" aria-expanded="false" aria-controls="collapseGroup">
                                    <Link to={"/inner/collection"} title={"Group Collections"}>
                                        <img className="svg-icon" src={ggSVG} alt='icon' />
                                        <img className="svg-icon hover" src={ggSVG2} alt='icon' />
                                    </Link>
                                    <p>Group Collections</p>
                                </div>



                                <div className="app-dropdown-item" data-bs-toggle="collapse" data-bs-target="#collapseNetwork" aria-expanded="false" aria-controls="collapseNetwork">
                                    <Link to={"/inner/volunteer"} title={"Volunteer Network"}>
                                        <img className="svg-icon" src={vnetworkSVG} alt='icon' />
                                        <img className="svg-icon hover" src={vnetworkSVG2} alt='icon' />

                                    </Link>
                                    <p>Volunteer Network</p>
                                </div>

                                <div className="app-dropdown-item" data-bs-toggle="collapse" data-bs-target="#collapseEkosha" aria-expanded="false" aria-controls="collapseEkosha">
                                    <Link to={`/inner/ekosha`} title={`EKOSHA`} >
                                        <img className="svg-icon" src={ekoshaSVG} alt='icon' />
                                        <img className="svg-icon hover" src={ekoshaSVG2} alt='icon' />
                                    </Link>
                                    <p>EKOSHA</p>
                                </div>

                            </div>
                        </div>
                    </li> */}
          {/* End here */}
        </ul>
      </nav>
    </>
  );
};

export default Topbar;
