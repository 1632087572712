/* eslint-disable jsx-a11y/anchor-is-valid */
import Sidebar from "../../Sidebar";
import Topbar from "../../Topbar";
import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";

import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { BsFillBasket3Fill } from "react-icons/bs";
import {
  acceptWithdrawalRequest,
  getAdminCommissionTransactionsHistory,
  rejectWithdrawalRequest,
} from "features/admin/admin-control/adminService";
import { IoSearch } from "react-icons/io5";
import "./splitcommission.css";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";

const SplitCommissionContent = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // const checkStatus = () => {};
  const [loading, setLoading] = useState(false);
  const [commission, setCommission] = useState([]);

  const fetchAdminCommission = async () => {
    try {
      setLoading(true);
      const resp = await getAdminCommissionTransactionsHistory();

      if (resp) {
        toast.success(resp.message);
        setCommission(resp.data.withdrawal_request.data);
        setLoading(false);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminCommission();
  }, []);

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(0);
  };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page - 1); // Convert to zero-based index
  // };

  const filteredCom = commission?.filter(
    (com) =>
      com?.account_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      com?.bank_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      com?.account_number?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const totalPageCount = Math.ceil(filteredCom?.length / pageSize);

  const startIndex = currentPage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredCom?.length);
  const paginatedCom = filteredCom?.slice(startIndex, endIndex);

  // Withdrawal Status

  const acceptWithdrawal = async (id) => {
    const data = { status: "approved" };
    try {
      const resp = await acceptWithdrawalRequest(id, data);
      if (resp.success) {
        toast.success(resp.message || "Withdrawal request accepted");
        fetchAdminCommission();
      } else {
        toast.error(resp.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error accepting withdrawal:", error);
      toast.error(error.response?.data?.message || "Failed to process request");
    }
  };

  const rejectWithdrawal = async (id) => {
    const data = { status: "rejected" };
    try {
      const resp = await rejectWithdrawalRequest(id, data);
      if (resp.success) {
        toast.success(resp.message || "Withdrawal request rejected");
        fetchAdminCommission();
      } else {
        toast.error(resp.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error rejecting withdrawal:", error);
      toast.error(error.response?.data?.message || "Failed to process request");
    }
  };

  return (
    <>
      <>
        <div className="inner-page-wrapper">
          {/* Sidebar */}
          <Sidebar />
          <div
            id="content-wrapper"
            className="content-wrapper d-flex flex-column"
          >
            {/* Main Content  */}
            <div id="content">
              {/* Topbar */}
              <Topbar />
              {/* Begin Page Content  */}
              <div className="container-fluid">
                <div className="col-lg-12">
                  <div className="precision-verify-card">
                    <h4 className="precision-card-header">
                      Commission Transactions
                    </h4>
                    <>
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <Spinner animation="grow" variant="primary" />
                          <Spinner animation="grow" variant="danger" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="info" />
                        </div>
                      ) : (
                        <>
                          {/* Search Feature */}
                          <div className="searchWrapper">
                            <div className="searchContainer">
                              <IoSearch />
                              <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder=" Search transaction"
                                className="searchInput"
                              />
                            </div>
                          </div>

                          {/* Table Section */}
                          <div className="table-responsive">
                            <Table responsive="sm" hover>
                              <thead>
                                <tr>
                                  <th width="5%">#</th>
                                  <th>Name</th>
                                  <th>Amount (₦)</th>
                                  <th>Account Number</th>
                                  <th>Bank Name</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {/* Handle No Data from Server */}
                                {commission?.length === 0 ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <p>
                                        <BsFillBasket3Fill
                                          style={{ fontSize: "44px" }}
                                        />
                                      </p>
                                      <p>No transactions available</p>
                                    </td>
                                  </tr>
                                ) : filteredCom?.length > 0 ? (
                                  /* Data Exists and Matches Search */
                                  paginatedCom.map((transaction, i) => (
                                    <tr
                                      key={transaction.id}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{i + 1}</td>
                                      <td className="text-capitalize">
                                        {transaction.account_name}
                                      </td>
                                      <td>{transaction.amount}</td>
                                      <td>{transaction.account_number}</td>
                                      <td>{transaction.bank_name}</td>
                                      <td>
                                        <span
                                          className={`badge ${
                                            transaction.status === "approved"
                                              ? "bg-success"
                                              : transaction.status === "pending"
                                              ? "bg-warning"
                                              : "bg-danger"
                                          } px-2 py-2 text-capitalize`}
                                        >
                                          {transaction.status}
                                        </span>
                                      </td>
                                      {/* Action */}
                                      <td className="dropdown text-center">
                                        <a
                                          className="dropdown-toggle menu text-dark text-muted"
                                          id="actionDropdown"
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="mdi mdi-cog-outline"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </a>
                                        <div
                                          className="dropdown-menu dropdown-menu-right preview-list"
                                          aria-labelledby="actionDropdown"
                                        >
                                          {transaction.status != "success" && (
                                            <>
                                              <a
                                                className="dropdown-item custom-drop"
                                                onClick={() =>
                                                  acceptWithdrawal(
                                                    transaction.id
                                                  )
                                                }
                                              >
                                                <FaCheckCircle
                                                  style={{
                                                    color: "green",
                                                    marginRight: "4px",
                                                  }}
                                                />
                                                <span>Accept</span>
                                              </a>
                                              <a
                                                className="dropdown-item custom-drop"
                                                onClick={() =>
                                                  rejectWithdrawal(
                                                    transaction.id
                                                  )
                                                }
                                              >
                                                <MdError
                                                  style={{
                                                    color: "red",
                                                    marginRight: "4px",
                                                  }}
                                                />
                                                <span>Reject</span>
                                              </a>
                                            </>
                                          )}
                                          {transaction.status == "success" && (
                                            <div
                                              className="alert alert-info text-center dropdown-item m-0 p-2"
                                              style={{ fontSize: "12px" }}
                                            >
                                              Payment Successful
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  /* No Search Results */
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <p>
                                        <BsFillBasket3Fill
                                          style={{ fontSize: "44px" }}
                                        />
                                      </p>
                                      <p>No results found for your search</p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>

                          {/* Pagination and Per Page Options */}
                          {commission?.length > 0 && (
                            <div className="d-flex justify-content-end mt-3  align-middle gap-3 ">
                              <div className="d-flex align-middle gap-2 align-items-center">
                                <span>Per page</span>
                                <select
                                  className="select"
                                  value={pageSize}
                                  onChange={handlePageSizeChange}
                                >
                                  {[10, 20, 30, 50].map((size) => (
                                    <option key={size} value={size}>
                                      {size}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div>
                                <Pagination
                                  count={totalPageCount}
                                  page={currentPage + 1}
                                  onChange={(value) =>
                                    setCurrentPage(value - 1)
                                  }
                                  color="primary"
                                  shape="rounded"
                                  size="small"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SplitCommissionContent;
