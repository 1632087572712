import React from "react";
import InnerFooter from "../InnerFooter";
import CommissionTransaction from "./CommissionTransaction";

const Commission = () => {
  return (
    <>
      <CommissionTransaction />
      <InnerFooter />
    </>
  );
};

export default Commission;
