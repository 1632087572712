import { Breadcrumb, Button, Modal, Spinner, Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import "./Wallet.css";
import { useEffect, useState } from "react";
import WalletTopUp from "components/PrecisionVerify/WalletTopUp";
import {
  getUserWallet,
  getUserWalletTransactions,
  verifyPayment,
} from "features/user/wallet/userWalletService";
import { toast } from "react-toastify";
// @mui
import { Pagination } from "@mui/material";
import Moment from "react-moment";
import { ImSpinner3 } from "react-icons/im";
import CommissionWithdrawal from "components/PrecisionVerify/CommissionWithdrawal";
import { useSelector } from "react-redux";

const WalletContent = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  // Gget user commission balance
  const userCommission = useSelector(
    (state) => state.auth.user.data.user.commission_balance
  );

  //  Modal states
  const [show, setShow] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseCommission = () => setShowCommission(false);
  const handleShowCommission = () => setShowCommission(true);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [commission, setCommission] = useState("");
  const [walletTransactions, setWalletTransactions] = useState(null);

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchUserWallet = async () => {
    try {
      setLoading(true);
      const resp = await getUserWallet();

      if (resp.success) {
        // toast.success(resp.message)
        setWallet(resp.data.user_wallet);
        setLoading(false);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };

  // Fetch Commission
  const fetchUserCommission = async () => {
    try {
      setLoading(true);
      const resp = await getUserWallet();

      if (resp.success) {
        toast.success(resp.message);
        // setCommission(5);
        // setCommission(resp.data.user_wallet);
        setLoading(false);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };

  const checkStatus = async (transRef) => {
    const resp = await verifyPayment({ transRef });

    if (resp.success) {
      toast.success(resp.message);
      fetchUserWalletTransactions();
      fetchUserWallet();
      fetchUserCommission();
    }
  };

  const fetchUserWalletTransactions = async () => {
    try {
      setLoading(true);
      const resp = await getUserWalletTransactions(page, perPage);

      if (resp.success) {
        // toast.success(resp.message)
        setWalletTransactions(
          resp.data.transactions.data.length
            ? resp.data.transactions.data
            : null
        );
        const pageCount = Math.ceil(
          resp.data.transactions.total / resp.data.transactions.per_page
        );
        setPageCount(pageCount);
        setLoading(false);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserWallet();
    fetchUserCommission();
    setCommission(50);
  }, []);

  useEffect(() => {
    fetchUserWalletTransactions();
  }, [perPage, page]);

  return (
    <>
      <div className="inner-page-wrapper">
        {/* Sidebar */}
        <Sidebar />
        <div
          id="content-wrapper"
          className="content-wrapper d-flex flex-column"
        >
          {/* Main Content  */}
          <div id="content">
            {/* Topbar */}
            <Topbar />

            {/* Begin Page Content  */}
            <div className="container-fluid">
              {/* Page Heading  */}
              <div className="d-sm-flex align-items-center justify-content-between mb-5">
                <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                  Wallet
                </h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Wallet</Breadcrumb.Item>
                </Breadcrumb>
              </div>

              {/* Content Row  */}
              <div className="pb-5">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="info" />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-4 mb-4">
                        <div className="wallet-wrapper">
                          <div className="d-flex wallet-content">
                            <div>
                              <h6>Your Wallet</h6>
                            </div>
                            <div>
                              <h4>
                                <i className="mdi mdi-account-convert-outline"></i>
                              </h4>
                            </div>
                          </div>
                          <div className="d-flex wallet-content">
                            {wallet && (
                              <div>
                                <p className="m-0">Balance</p>
                                <h2 className="m-0">
                                  ₦{Number(wallet.total).toLocaleString()}
                                </h2>
                              </div>
                            )}
                            <div className="d-flex align-items-end">
                              <button
                                className="btn btn-outline-warning btn-sm px-2"
                                onClick={handleShow}
                              >
                                <i className="mdi mdi-plus"></i> Top up
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* ------------------------------------ commission ------------------------------------ */}
                        <br />

                        <div
                          className="commission-wrapper"
                          style={{ margintop: "20px" }}
                        >
                          <div className="d-flex wallet-content">
                            <div>
                              <h6>Your Commission</h6>
                            </div>
                            <div>
                              <h4>
                                <i className="mdi mdi-account-convert-outline"></i>
                              </h4>
                            </div>
                          </div>
                          <div className="d-flex wallet-content">
                            {/* Change the wallet details */}
                            {commission && (
                              <div>
                                <p className="m-0">Current Split Commission</p>
                                <h2 className="m-0">
                                  ₦{Number(userCommission).toLocaleString()}
                                </h2>
                              </div>
                            )}
                            <div className="d-flex align-items-end">
                              <button
                                className="btn btn-outline-warning btn-sm px-2"
                                onClick={handleShowCommission}
                              >
                                <i className="mdi mdi-plus"></i> Withdraw
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="precision-verify-card">
                          <h4 className="precision-card-header">
                            Wallet Transactions
                          </h4>
                          <div className="table-responsive">
                            <Table responsive="sm" hover>
                              <thead>
                                <tr>
                                  <th width="5%">#</th>
                                  <th>Transaction reference</th>
                                  <th>Amount (₦)</th>
                                  <th>Date</th>
                                  <th>Description</th>
                                  <th>Status</th>
                                  <th className="text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {walletTransactions ? (
                                  walletTransactions.map((transaction, i) => (
                                    <tr
                                      key={transaction.id}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>{i + 1}</td>
                                      <td>
                                        {transaction.transaction_reference}
                                      </td>
                                      <td
                                        className={`font-weight-bold ${
                                          transaction.transaction_type ==
                                          "deposit"
                                            ? "text-success"
                                            : "text-danger"
                                        }`}
                                      >
                                        {transaction.transaction_type ==
                                        "deposit"
                                          ? "+"
                                          : "-"}
                                        {Number(
                                          transaction.amount
                                        ).toLocaleString()}
                                      </td>
                                      <td>
                                        <Moment format="MMM DD, YYYY, h:mma">
                                          {transaction.createdAt}
                                        </Moment>
                                      </td>
                                      <td>{transaction.payment_purpose}</td>
                                      <td>
                                        <span
                                          className={`badge ${
                                            transaction.status == "success"
                                              ? "bg-success"
                                              : transaction.status == "pending"
                                              ? "bg-warning"
                                              : "bg-danger"
                                          } 
                                                                                px-2 py-2`}
                                        >
                                          {transaction.status}
                                        </span>
                                      </td>
                                      <td className="dropdown text-center">
                                        <a
                                          className="dropdown-toggle menu text-dark text-muted"
                                          id="actionDropdown"
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="mdi mdi-cog-outline"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </a>
                                        <div
                                          className="dropdown-menu dropdown-menu-right preview-list"
                                          aria-labelledby="actionDropdown"
                                        >
                                          {transaction.status != "success" && (
                                            <a
                                              className="dropdown-item custom-drop"
                                              onClick={() =>
                                                checkStatus(
                                                  transaction.transaction_reference
                                                )
                                              }
                                            >
                                              <i className="mdi mdi-tag-check-outline mr-2 text-info"></i>
                                              <span>Check Status</span>
                                            </a>
                                          )}
                                          {transaction.status == "success" && (
                                            <div
                                              className="alert alert-info text-center dropdown-item m-0 p-2"
                                              style={{ fontSize: "12px" }}
                                            >
                                              Payment Successful
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="7">
                                      <div className="alert alert-info text-center">
                                        <h6>No record found</h6>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {walletTransactions && (
                          <div className="d-flex justify-content-end mt-3">
                            <div className="d-flex">
                              per page
                              <select
                                className="ml-1"
                                name="perPage"
                                onChange={(e) => setPerPage(e.target.value)}
                                value={perPage}
                              >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                              </select>
                            </div>
                            <Pagination
                              onChange={handlePageChange}
                              count={pageCount}
                              color="primary"
                              shape="rounded"
                              size="small"
                              page={page}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* top up modal component import */}
      {wallet && (
        <WalletTopUp
          show={show}
          closeModal={handleClose}
          currentUser={currentUser}
          walletId={wallet.id}
        />
      )}
      {/* Commission */}
      {showCommission && (
        <CommissionWithdrawal
          showCommission={showCommission}
          closeCommissionModal={handleCloseCommission}
          currentUser={currentUser}
          walletId={wallet.id}
        />
      )}
    </>
  );
};

export default WalletContent;
