import React from "react";
import SplitCommissionContent from "./SplitCommissionContent";
import InnerFooter from "pages/inner/InnerFooter";

const SplitCommission = () => {
  return (
    <>
      <SplitCommissionContent />
      <InnerFooter />
    </>
  );
};

export default SplitCommission;
