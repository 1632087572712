import Forminputgroup from "components/reusables/Forminputgroup";
import {
  verifyAccountDetails,
  withdrawalRequest,
} from "features/user/wallet/userWalletService";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import "./WalletTopup.css";
import FormSelectComponent from "components/reusables/FormSelectComponent";
import axios from "axios";
import { useSelector } from "react-redux";

export default function CommissionWithdrawal({
  showCommission,
  closeCommissionModal,
  currentUser,
}) {
  const [formData, setFormData] = useState({
    amount: "",
    user: currentUser !== null ? currentUser.data.user.id : "",
    bank_name: "",
    bank_code: "",
    account_number: "",
    account_name: "",
  });
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  // Get Commission
  const userCommission = useSelector(
    (state) => state.auth.user.data.user.commission_balance
  );

  // Check Amount input

  const fetchBanks = async () => {
    try {
      const response = await axios.get("https://api.paystack.co/bank");
      if (response.data.status && Array.isArray(response.data.data)) {
        const formattedBanks = response.data.data.map((bank) => ({
          value: bank.name, // Use 'code' for bank_code
          caption: bank.name, // Use 'name' as the display text
          code: bank.code, // Use 'name' as the display text
        }));
        setBanks(formattedBanks);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching bank names:", error);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  const handleCloseCommission = () => {
    closeCommissionModal();
    setFormData({
      amount: "",
      user: currentUser !== null ? currentUser.data.user.id : "",
      bank_name: "",
      bank_code: "",
      account_number: "",
      account_name: "",
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Check the total amount of the commission if it is more than zero
    if (name === "amount" && Number(value) > userCommission) {
      toast.error("Amount exceeds available balance");
      setFormData((prevState) => ({
        ...prevState,
        amount: userCommission.toString(),
      }));
      return;
    }

    // Update form data state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Handle bank name selection
    if (name === "bank_name") {
      const selectedBank = banks.find((bank) => bank.caption === value);
      if (selectedBank) {
        setFormData((prevState) => ({
          ...prevState,
          bank_code: selectedBank.code, // Populate bank_code
        }));
      }
    }

    // Fetch account details if both bank_code and a 10-digit account number are available
    if (formData.account_number.length === 10) {
      try {
        const accountName = await verifyAccountDetails({
          account_number: formData.account_number,
          bank_code: formData.bank_code,
        });
        setFormData((prevState) => ({
          ...prevState,
          account_name: accountName?.data?.data.account_name, // Populate account_name
        }));
      } catch (error) {
        console.error("Failed to fetch account details:", error);
        toast.error("Failed to validate account details. Please try again.");
      }
    }
  };

  const handleCommissionRequest = async (e) => {
    e.preventDefault();

    if (!formData.amount) return toast.error("Amount cannot be empty");
    if (!formData.bank_name) return toast.error("Bank Name cannot be empty");
    if (!formData.account_number)
      return toast.error("Account Number cannot be empty");
    if (formData.amount < 1000) {
      toast.error("The least amount you can withdraw is ₦1000");
      return;
    }

    try {
      setLoading(true);
      const resp = await withdrawalRequest(formData);
      if (resp.success) {
        setLoading(false);
        toast.success(resp.message);
        handleCloseCommission();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
      handleCloseCommission();
    }
  };

  return (
    <>
      <Modal show={showCommission} onHide={handleCloseCommission}>
        <Modal.Header closeButton>
          <Modal.Title className="prompt-title font-weight-bold">
            Commission Withdrawal
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleCommissionRequest}>
          <Modal.Body>
            <div className="mb-4">
              <FormSelectComponent
                selectName="bank_name"
                selectOption="Select Bank"
                value={formData.bank_name}
                itemList={banks}
                onChange={handleChange}
                size="form-control-lg"
              />
            </div>

            <div className="form-body pb-3">
              <Forminputgroup
                inputLabel="Account Number"
                inputName="account_number"
                inputType="text"
                inputRequire={true}
                value={formData.account_number}
                onChange={handleChange}
              />
            </div>
            <div className="form-body pb-3">
              <Forminputgroup
                inputLabel="Account Name"
                inputName="account_name"
                inputType="text"
                inputRequire={true}
                value={formData.account_name}
                isDisable={true}
              />
            </div>
            <div className="form-body pb-3">
              <Forminputgroup
                inputLabel="Bank Code"
                inputName="bank_code"
                inputType="text"
                inputRequire={true}
                value={formData.bank_code}
                isDisable={true}
              />
            </div>
            <div className="form-body pb-3">
              <Forminputgroup
                inputLabel="Amount to Withdraw (₦)"
                inputName="amount"
                inputType="text"
                inputRequire={true}
                value={formData.amount}
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              type="submit"
              disabled={
                !Number(formData.amount) ||
                Number(formData.amount) <= 0 ||
                Number(formData.amount) > userCommission ||
                userCommission <= 0
              }
            >
              Withdraw
              {loading && <ImSpinner3 className="icon-spin" />}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
