import { Breadcrumb, Spinner } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import "./Verification.css";
import { useEffect, useState } from "react";
import FormSelectComponent from "components/reusables/FormSelectComponent";
import VirtualNin from "components/PrecisionVerify/Verification/VirtualNIN";
import DriverLicense from "components/PrecisionVerify/Verification/DriverLicense";
import NubanBvn from "components/PrecisionVerify/Verification/NubanBvn";
import BvnBasic from "./BVNbasic";
import VotersCard from "components/PrecisionVerify/Verification/VotersCard";
import Cac from "components/PrecisionVerify/Verification/CAC";
import Tin from "components/PrecisionVerify/Verification/TIN";
import LicensePlate from "components/PrecisionVerify/Verification/LicensePlate";
import {
  getUserWallet,
  verifyIdentity,
} from "features/user/wallet/userWalletService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserVerificationTypes,
  getVerificationTypes,
} from "features/generic/verification/verificationTypeSlice";
import { ImSpinner3 } from "react-icons/im";
import VerificationResult from "components/PrecisionVerify/VerificationResult";
import Address from "components/PrecisionVerify/Verification/Address";
import Guarantor from "components/PrecisionVerify/Verification/Guarantor";
import InternationalPassport from "components/PrecisionVerify/Verification/Passport";
import LivenessCheck from "components/PrecisionVerify/Verification/LivenessCheck";
import NIN from "components/PrecisionVerify/Verification/NIN";
import FaceCapture from "components/PrecisionVerify/Verification/Facematch";

const VerificationContent = () => {
  //  user form data
  const [formData, setFormData] = useState({
    idType: "",
    cost: "",
    code: "",
    name: "",
  });
  const [currentSelection, setCurrentSelection] = useState(null);

  const [wallet, setWallet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verificationResult, setVerificationResult] = useState(null);
  const [r, setR] = useState("");

  //  Modal states
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const {
    userVerificationTypes: verificationTypes,
    isError,
    isLoading,
    isSuccess,
    message,
  } = useSelector((state) => state.verificationTypes);

  const selectOptions = verificationTypes
    ? verificationTypes.items.map((v) => ({
        id: v.id,
        value: v.id,
        caption: v.name,
      }))
    : [];

  const handleChange = (e) => {
    setFormData({ [e.target.name]: e.target.value });
    const selectedVerification = verificationTypes.items.filter(
      (v) => v.id == e.target.value
    );
    setR((Math.random() + 1).toString(36).substring(2));
    setCurrentSelection(selectedVerification);
  };

  //   Refactored Code
  //   const handleChange = (e) => {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [e.target.name]: e.target.value,
  //     }));
  //     const selectedVerification = verificationTypes.items.filter(
  //       (v) => v.id == e.target.value
  //     );
  //     console.log("Updated formData:", {
  //       ...formData,
  //       [e.target.name]: e.target.value,
  //     }); // Debugging
  //     setR((Math.random() + 1).toString(36).substring(2));
  //     setCurrentSelection(selectedVerification);
  //   };

  const fetchUserWallet = async () => {
    try {
      // setLoading(true)
      const resp = await getUserWallet();

      if (resp.success) {
        // toast.success(resp.message)
        setWallet(resp.data.user_wallet);
        // setLoading(false)
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      // setLoading(false)
    }
  };

  const mergeData = (data) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
  };

  const makeVerification = async (e) => {
    e.preventDefault();
    try {
      if (formData.idNumber == "") {
        return toast.error("Enter an ID Number");
      }
      if (formData.firstname == "") {
        return toast.error("First Name is required");
      }
      if (formData.lastname == "") {
        return toast.error("Last Name is required");
      }
      if (
        (currentSelection ? Number(currentSelection[0].cost) : 0) >
        (wallet ? Number(wallet.total) : 0)
      ) {
        return toast.error("Insufficient wallet balance");
      }
      setLoading(true);
      formData.cost = Number(currentSelection[0].cost);
      formData.code = currentSelection[0].code;
      formData.name = currentSelection[0].name;

      const resp = await verifyIdentity(formData);

      if (resp.success) {
        toast.success(resp.message);
        fetchUserWallet();
        setVerificationResult(resp.data);
        setLoading(false);
        handleShow();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserWallet();
    dispatch(getUserVerificationTypes());
  }, []);

  useEffect(() => {
    // Import and load your JavaScript script if the page has loaded
    if (["14", "15", "16", "17"].includes(formData.idType)) {
      const script = document.createElement("script");
      script.src = "https://dashboard.qoreid.com/qoreid-sdk/qoreid.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [formData]);

  return (
    <>
      <div className="inner-page-wrapper">
        {/* Sidebar */}
        <Sidebar />
        <div
          id="content-wrapper"
          className="content-wrapper d-flex flex-column"
        >
          {/* Main Content  */}
          <div id="content">
            {/* Topbar */}
            <Topbar />

            {/* Begin Page Content  */}
            <div className="container-fluid">
              {/* Page Heading  */}
              <div className="d-sm-flex align-items-center justify-content-between mb-5">
                <h1 className="h3 mb-0 text-gray-800 font-weight-bold">
                  Quick Verification
                </h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Quick Verification</Breadcrumb.Item>
                </Breadcrumb>
              </div>

              {/* Content Row  */}
              <div className="pb-5">
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="grow" variant="primary" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="info" />
                  </div>
                ) : (
                  <div className="verification-wrapper">
                    <div className="verification-header">
                      <h2>Quick Verification</h2>
                      <p>
                        Quick Verification allows you to instantly verify user
                        identities. Each verification attracts a service charge
                        that is deducted from your wallet.
                      </p>
                    </div>
                    <div className="precision-verify-card shadow-lg verify-card p-md-4">
                      <div className="row mb-3">
                        {currentSelection && (
                          <div className="col-lg-9">
                            <div className="alert alert-info wallet-info">
                              <p className="m-0">
                                Verification costs &nbsp;
                                <span className="font-weight-bold text-danger">
                                  ₦
                                  {currentSelection
                                    ? Number(
                                        currentSelection[0].cost
                                      ).toLocaleString()
                                    : 0}
                                </span>
                                &nbsp; per request.
                              </p>
                            </div>
                          </div>
                        )}
                        {wallet && (
                          <div className="col-lg-3">
                            <div className="wallet-balance ml-auto">
                              <p>Wallet Balance</p>
                              <p>₦{Number(wallet.total).toLocaleString()}</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="verification-form-wrapper">
                        <form onSubmit={makeVerification}>
                          <div className="form-body pb-3">
                            <div className="mb-4">
                              <FormSelectComponent
                                selectName="idType"
                                selectOption="Identity Type"
                                value={formData.idType}
                                itemList={selectOptions}
                                onChange={handleChange}
                                size="form-control-lg"
                              />
                            </div>
                            {formData.idType == 1 && (
                              <VirtualNin setValue={mergeData} />
                            )}
                            {formData.idType == 2 && (
                              <DriverLicense setValue={mergeData} />
                            )}
                            {formData.idType == 3 && (
                              <NubanBvn setValue={mergeData} type={"bvn"} />
                            )}
                            {formData.idType == 4 && (
                              <BvnBasic setValue={mergeData} />
                            )}
                            {formData.idType == 5 && (
                              <BvnBasic setValue={mergeData} />
                            )}
                            {formData.idType == 6 && (
                              <VotersCard setValue={mergeData} />
                            )}
                            {formData.idType == 7 && (
                              <Cac setValue={mergeData} />
                            )}
                            {formData.idType == 8 && (
                              <Tin setValue={mergeData} />
                            )}
                            {formData.idType == 9 && (
                              <LicensePlate setValue={mergeData} />
                            )}
                            {formData.idType == 10 && (
                              <NubanBvn setValue={mergeData} type={"regular"} />
                            )}
                            {formData.idType == 11 && (
                              <Address setValue={mergeData} />
                            )}
                            {formData.idType == 12 && (
                              <Guarantor setValue={mergeData} />
                            )}
                            {formData.idType == 13 && (
                              <InternationalPassport setValue={mergeData} />
                            )}
                            {
                              formData.idType == 19 && <></>
                              // <NIN
                              //     setValue={mergeData}
                              // />
                            }
                            {
                              formData.idType == 20 && <></>
                              // <BvnBasic
                              //     setValue={mergeData}
                              // />
                            }
                            {["14", "15", "16", "17", "18"].includes(
                              formData.idType
                            ) && (
                              <div className="mt-5">
                                <LivenessCheck
                                  typeId={formData.idType}
                                  selection={currentSelection[0]}
                                  customerRef={r}
                                />
                              </div>
                            )}
                            {["21", "22", "23", "24", "25"].includes(
                              formData.idType
                            ) && (
                              <div className="mt-5">
                                <FaceCapture setValue={mergeData} />
                              </div>
                            )}
                            {["14", "15", "16", "17", "18"].includes(
                              formData.idType
                            ) == false && (
                              <div className="mb-4 mt-5">
                                <button
                                  //   disabled={
                                  //     formData?.idType == "" || !formData.isFaceOk
                                  //   }
                                  disabled={
                                    !formData.idType ||
                                    (formData.idType &&
                                      !formData.isFaceOk &&
                                      ["21", "22", "23", "24", "25"].includes(
                                        formData.idType
                                      ))
                                  }
                                  className="btn btn-danger padding-10 w-100"
                                >
                                  Submit
                                  {loading && (
                                    <ImSpinner3 className="icon-spin" />
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {verificationResult && (
        <VerificationResult
          show={show}
          closeModal={handleClose}
          data={verificationResult}
          verificationInfo={currentSelection[0]}
          idNumber={formData.idNumber}
        />
      )}
    </>
  );
};

export default VerificationContent;
