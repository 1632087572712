import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Forminputgroup from "../../components/reusables/Forminputgroup";
import { ImSpinner3 } from "react-icons/im";
import { FaCheckDouble } from "react-icons/fa";
import { toast } from "react-toastify";
import logo from "../../assets/img/precisionverify/precision.png";

import { forgetUserPassword } from "features/admin/admin-control/adminService";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_CALL_BACK_URL
      : process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROD_CALL_BACK_URL
      : "";

  const [resetData, setResetdata] = useState({
    email: "",
    callbackUrl: `${url}/auth/reset-password`,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setResetdata({ ...resetData, email: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (resetData.email === "") {
      toast.error("Pls enter your email address");
    } else {
      try {
        setLoading(true);
        const resp = await forgetUserPassword(resetData);
        await console.log(resp);

        if (resp) {
          // toast.success(resp.message);
          setMessage(resp.message);
          setLoading(false);
          localStorage.setItem("resetEmail", resetData.email);
          setTimeout(() => {
            navigate("/auth");
          }, 6000);
        }
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message);
        setLoading(false);
      }
    }
  };

  return (
    <section className="section-sm bg-light-2 reg-bg">
      <div className="container px-4">
        <div className="row gx-4 gx-lg-5">
          <div className="inner-row">
            <div className="auth-form-wrap">
              <div className="navbar-brand logo-wrap align-items-center">
                <Link className="navbar-brand" to={`/`}>
                  <img className="logo auth-logo" src={logo} alt="giving.ng" />
                </Link>
              </div>
              <div className="auth-form">
                <form autoComplete="off" name="reset-password">
                  <div className="form-header text-center">
                    <h3 className="form-title">Recover Your Password</h3>
                    <p>
                      We get it, stuff happens. Just enter your email address
                      below and we'll send you a link to reset your password!
                    </p>
                  </div>
                  <div className="form-body">
                    <Forminputgroup
                      inputLabel={`Email Address`}
                      inputName={`email`}
                      inputType={`email`}
                      inputRequire={`required`}
                      value={resetData.email}
                      onChange={handleChange}
                    />
                    {message !== "" ? (
                      <p
                        className="alert alert-success alert-dismissible fade show mt-3"
                        role="alert"
                      >
                        <strong>
                          <FaCheckDouble />
                        </strong>{" "}
                        {message}
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </p>
                    ) : (
                      <></>
                    )}
                    <div className="form-group btn-wrap">
                      <button
                        className="btn btn-primary btn-shadow btn-round btn-md btn-login"
                        onClick={handleSubmit}
                      >
                        Request Password Reset{" "}
                        {loading && <ImSpinner3 className="icon-spin" />}
                      </button>
                    </div>
                  </div>
                  <div className="form-footer text-center">
                    <p>
                      Remember your pasword?{" "}
                      <Link className="inline-link" to={`/auth`}>
                        Sign in here.{" "}
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
